import React, { useState } from "react"
import { StaticImage } from 'gatsby-plugin-image'
import * as visionModalStyles from "../../styles/components/vision-modal.module.scss"
import GeneralTableModal from "../../components/parts/general-table-modal"

export default function RelatedResource(props) {
  const styles = props.styles

  const [modalOpen, setModal] = useState(false);
  const openModal = (e) => {
    e.preventDefault();
    setModal(parseInt(e.currentTarget.getAttribute('data-num')))
  }
  const closeModal = (e) => {
    e.preventDefault();
    setModal(false)
  }
  let modalId = 0

  const tableData = <>
    <tr>
      <td>2025年度版<span className={visionModalStyles.recently}>最新</span></td>
      <td><a href="/pdf/about/implementation-policy-2025.pdf" rel="noreferrer noopener" className={visionModalStyles.archive} target="_blank">実施方針</a></td>
    </tr>
    <tr>
      <td>2024年度版</td>
      <td><a href="/pdf/about/implementation-policy-2024.pdf" rel="noreferrer noopener" className={visionModalStyles.archive} target="_blank">実施方針</a></td>
    </tr>
    <tr>
      <td>2023年度版</td>
      <td><a href="/pdf/about/implementation-policy-2023.pdf" rel="noreferrer noopener" className={visionModalStyles.archive} target="_blank">実施方針</a></td>
    </tr>
    <tr>
      <td>2022年度版</td>
      <td><a href="/pdf/about/implementation-policy-2022.pdf" rel="noreferrer noopener" className={visionModalStyles.archive} target="_blank">実施方針</a></td>
    </tr>
    <tr>
      <td>2021年度版</td>
      <td><a href="/pdf/about/implementation-policy-2021.pdf" rel="noreferrer noopener" className={visionModalStyles.archive} target="_blank">実施方針</a></td>
    </tr>
  </>
  
  return (
    <>
      <h2 className={styles.pageContentsHeading2}>関連資料</h2>

      <div className={styles.relatedLink}>
        <ul>
          <li className={`${styles.relatedLinkInnerList} ${styles.relatedLinkFlex}`}><a className={`${styles.linkText} ${styles.outbound}`} href="https://www.meti.go.jp/policy/energy_environment/global_warming/ggs/index.html" target="_blank" rel="noopener noreferrer">2050年カーボンニュートラルに伴うグリーン成長戦略（経済産業省）</a></li>
          <li className={`${styles.relatedLinkInnerList} ${styles.relatedLinkFlex}`}><a className={`${styles.linkText} ${styles.pdf}`} href="https://www.meti.go.jp/policy/energy_environment/global_warming/gifund/12.pdf" target="_blank" rel="noopener noreferrer">グリーンイノベーション基金事業の基本方針（経済産業省）</a></li>
          <li className={`${styles.relatedLinkInnerList} leading-9`}>◆ <a href="/#" onClick={openModal} data-num={modalId} className={styles.archiveButton}>グリーンイノベーション基金事業 実施方針</a>
            <GeneralTableModal
              title="グリーンイノベーション基金事業 実施方針"
              tableData={tableData}
              open={(modalOpen === modalId)}
              close={closeModal}
              />
          </li>
        </ul>
        <h4 className={styles.pageContentsHeading4}>◆ 広報資料</h4>
        <div className={styles.boxColumn}>
          <div className={styles.boxColumnItem}>
            <a href="/pdf/about/20201225012-4.pdf" target="_blank" rel="noopener noreferrer"><StaticImage src="../../images/about/related-resources-01.png" aria-label="「カーボンニュートラルの産業イメージ」（経済産業省）、広報資料" alt="" /></a>
            <p className="mt-1 pl-1"><small><a className={`${styles.linkText} ${styles.outbound}`} href="/pdf/about/20201225012-4.pdf" target="_blank" rel="noopener noreferrer">「カーボンニュートラルの産業イメージ」（経済産業省）、広報資料</a></small></p>
          </div>
          <div className={styles.boxColumnItem}>
            <a href="/pdf/about/20201225012-5.pdf" target="_blank" rel="noopener noreferrer"><StaticImage src="../../images/about/related-resources-02.png" aria-label="「カーボンニュートラルの広がり」（経済産業省）、広報資料" alt="" /></a>
            <p className="mt-1 pl-1"><small><a className={`${styles.linkText} ${styles.outbound}`} href="/pdf/about/20201225012-5.pdf" target="_blank" rel="noopener noreferrer">「カーボンニュートラルの広がり」（経済産業省）、広報資料</a></small></p>
          </div>
        </div>
        <div className={styles.boxColumn}>
          <div className={styles.boxColumnItem}>
            <a href="https://www.nedo.go.jp/content/100955752.pdf" target="_blank" rel="noopener noreferrer"><StaticImage src="../../images/about/related-resources-03.png" aria-label="グリーンイノベーション基金事業パンフレット　2025年1月発行" alt="" /></a>
            <p className="mt-1 pl-1"><small><a className={`${styles.linkText} ${styles.pdf}`} href="https://www.nedo.go.jp/content/100955752.pdf" target="_blank" rel="noopener noreferrer">グリーンイノベーション基金事業パンフレット　2025年1月発行</a></small></p>
          </div>
          <div className={styles.boxColumnItem}>
          </div>
        </div>
        
        <h4 className={styles.pageContentsHeading4}>◆ 技術戦略研究センター（TSC）調査分析レポート</h4>
        <ul>
          <li className={`${styles.relatedLinkInnerList} pl-2`}><a className={`${styles.linkText} ${styles.outbound}`} href="https://www.nedo.go.jp/library/ZZNA_100091.html" target="_blank" rel="noopener noreferrer">「海外トレンド：COP28に向けたCNに関する海外主要国（米・中・EU・英・独・インドネシア・インド・UAE・サウジアラビア）の動向」</a></li>
          <li className={`${styles.relatedLinkInnerList} pl-2`}><a className={`${styles.linkText} ${styles.outbound}`} href="https://www.nedo.go.jp/library/ZZNA_100076.html" target="_blank" rel="noopener noreferrer">「海外トレンド：COP27に向けたカーボンニュートラルに関する海外主要国（米・中・EU・英・独・インドネシア・エジプト・インド）の動向」</a></li>
          <li className={`${styles.relatedLinkInnerList} pl-2`}><a className={`${styles.linkText} ${styles.outbound}`} href="https://www.nedo.go.jp/library/ZZNA_100065.html" target="_blank" rel="noopener noreferrer">「海外トレンド：COP26に向けたカーボンニュートラルに関する海外主要国（米・中・EU・英）の動向」</a></li>
        </ul>
      </div>
    </>
  )
}

